const fontFamilies = {
  latto: 'Latto',
  funnelDisplay: 'Funnel Display',
  workSans: 'Work Sans',
};

const fontWeights = {
  latto: 'Latto',
  funnelDisplay: 'Funnel Display',
};

export default {
  fontWeights,
  fontFamilies,
};
