import { Box, useMediaQuery } from '@mui/material';
import ConnectBanner from 'assets/connect-banner.png';
import ConnectBannerMobile from 'assets/connect-banner-mobile.png';

const Connect: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 425px)');
  const handleRedirect = () => {
    const connectUrl = import.meta.env.VITE_CONNECT_URL;
    window.location.href = connectUrl;
  };
  return (
    <Box
      padding={isMobile ? '0px' : '20px'}
      onClick={handleRedirect}
      height={'100%'}
    >
      <img
        src={isMobile ? ConnectBannerMobile : ConnectBanner}
        alt="ConnectBanner"
        loading="lazy"
        width="100%"
        height={isMobile ? '100%' : 'auto'}
        style={{ borderRadius: isMobile ? '0px' : '8px', cursor: 'pointer' }}
      />
    </Box>
  );
};

export default Connect;
