import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';

export const VerticalDivider = styled.div`
  width: 0px;
  height: 24px;

  border: 1px solid #928176;

  flex: 1;
  margin: 20px 16px;
`;

export const Wrapperlogo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledBreadcrumb = styled.div`
  .bread {
    display: flex;
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.tertiaryIntermediate};
  text-decoration-color: ${colors.tertiaryIntermediate};
  text-underline-offset: 1px;
`;
