import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Box, ListItemButton, Collapse, Divider, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ChevronRight } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { H6, P3, P4, Caps } from 'components/common/typography/styles';
import CustomTooltip from 'components/common/tooltips';
import LotesDropdown from 'components/common/dropdowns/lotes';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import type { Perimeter } from 'utils/types';
import api from 'services/api/api';
import { Strings } from 'constants/strings';
import { useSnackbar } from 'notistack';
import { toJS } from 'mobx';
import MapDraw from 'assets/icons/extraIcons/map-draw.svg?react';
import { normalizeLong } from 'utils/mapUtils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import type { LotPolygon } from 'models/map';
import { ListHoverItemLot } from 'components/common/dropdowns/lotes/index.styled';
import TutorialGif from 'assets/Lotes.gif';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import Button from '../../buttons/button';
import { sleep } from 'utils/common';
import { isEqual, map, omit } from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputCheckbox from 'components/common/inputs/checkbox';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

interface LotesSelectionProps {
  fieldId: number;
  hiddenFileInput: React.RefObject<HTMLInputElement>;
  perimeters: Perimeter[];
  uploadKML: (e: React.ChangeEvent<HTMLInputElement>) => void;
  closeDrawer: (state: boolean) => void;
  lots: LotPolygon[];
  setLots: (lots: LotPolygon[]) => void;
  loadingKML: boolean;
  setZoomPolygon: (polygon: number[][]) => void;
  hiddenLots: string[];
  setHiddenLots: (lots: string[]) => void;
}

type Payload = {
  id: number;
  poligono: number[][];
};

const HiddenInput = styled('input')({
  display: 'none',
});

const LotesSelection: React.FC<LotesSelectionProps> = (props) => {
  const {
    fieldId,
    hiddenFileInput,
    perimeters,
    uploadKML,
    closeDrawer,
    lots,
    setLots,
    loadingKML,
    setZoomPolygon,
    hiddenLots,
    setHiddenLots,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    fieldsStore,
    mapStore: { setRefreshPolygons },
    fieldsStore: { setChangesSaved, getFieldById, getMyLots },
  } = useStores();
  const [selectedIdLot, setSelectedIdLot] = useState<number>(0); // Para saber que lote esta seleccionado
  const [hideOptionalLots, setHideOptionalLots] = useState(false); // Para saber si se ocultan los lotes opcionales
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [showTutorial, setShowTutorial] = useState(false);
  const open = Boolean(anchorEl);
  const [gpsIcon, setGpsIcon] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const initialLots = useRef<LotPolygon[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (lot: LotPolygon | null) => {
    const lotPolygonSelected = lots.find((l) => l.id === selectedIdLot);
    if (lotPolygonSelected) {
      lotPolygonSelected.poligono = lot ? lot.poligono : [];
      lotPolygonSelected.nombrePoligono = lot ? lot.nombrePoligono : null;
      lotPolygonSelected.color = lot ? lot.color : colors.complementaryGreen;
      lotPolygonSelected.assigned = !!lot;
      lotPolygonSelected.name = lot?.name;
      lotPolygonSelected.load = lot?.load;
      const newLots = lots.map((l) =>
        l.id === selectedIdLot ? lotPolygonSelected : l,
      );
      setLots(newLots);
    }
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    const payload: Payload[] = [];
    for (const lot of lots) {
      if (lot.poligono.length > 0) {
        payload.push({
          id: lot.id,
          poligono: lot.poligono.map((p) => [p[0], normalizeLong(p[1])]),
        });
      }
    }

    if (payload) {
      const response = await api.me.patchLots(payload as Payload[]);
      if (!response.kind) {
        initialLots.current = structuredClone(lots);
        enqueueSnackbar(Strings.forms.fieldModification.success, {
          variant: 'success',
        });
      }
      if (response.kind) {
        enqueueSnackbar(Strings.forms.fieldModification.error, {
          variant: 'error',
        });
      }
    }

    await getMyLots(fieldId);
    await getFieldById(fieldId);
    setRefreshPolygons();
    await sleep(1000);
    setIsLoading(false);
    setChangesSaved(true);
  };

  const selectedHasPolygon = perimeters.filter(
    (perimeter) =>
      !lots.find(
        (item) =>
          item.nombrePoligono === perimeter.nombrePoligono && item.assigned,
      ),
  );

  useEffect(() => {
    const lotsParsed = toJS(fieldsStore.lots).map((lot) => {
      return {
        ...lot,
        assigned: lot.poligono.length > 0,
      };
    });
    setLots(lotsParsed);
    initialLots.current = structuredClone(lotsParsed);
  }, [fieldsStore.lots, setLots]);

  const isNewLotsAssigned = () => {
    const omitedValues = [
      'nombrePoligono',
      'color',
      'assigned',
      'name',
      'load',
      'especie',
      'isPlanificado',
    ];
    const omitedLots = map(lots, (obj) => omit(obj, omitedValues));
    const omitedInitial = map(initialLots.current, (obj) =>
      omit(obj, omitedValues),
    );
    return isEqual(omitedLots, omitedInitial);
  };

  const hasNotPlanificados = lots.filter((lot) => !lot.isPlanificado);
  const hasPlanificado = lots.filter((lot) => lot.isPlanificado);
  const hasAssignedLots = lots.filter((lot) => lot.assigned);
  const toggleLotVisibility = (polygonName: string) => {
    const currentHiddenLots = hiddenLots || [];
    if (currentHiddenLots.includes(polygonName)) {
      setHiddenLots(
        currentHiddenLots.filter(
          (nombrePoligono) => nombrePoligono !== polygonName,
        ),
      );
    } else {
      setHiddenLots([...currentHiddenLots, polygonName]);
    }
  };

  const handleHideAllActiveLots = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const activeLotNombresPoligonos = hasPlanificado
      .map((lot) => lot.nombrePoligono)
      .filter(
        (nombre): nombre is string => nombre !== null && nombre !== undefined,
      );

    if (event.target.checked) {
      const currentHidden = hiddenLots.filter(
        (nombrePoligono) => !activeLotNombresPoligonos.includes(nombrePoligono),
      );
      setHiddenLots([...currentHidden, ...activeLotNombresPoligonos]);
    } else {
      setHiddenLots(
        hiddenLots.filter(
          (nombrePoligono) =>
            !activeLotNombresPoligonos.includes(nombrePoligono),
        ),
      );
    }
  };

  const handleHideAllOptionalLots = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const optionalNombresPoligonos = hasNotPlanificados
      .map((lot) => lot.nombrePoligono)
      .filter(
        (nombre): nombre is string => nombre !== null && nombre !== undefined,
      );
    if (event.target.checked) {
      // Ocultar todos los lotes opcionales
      const currentHidden = hiddenLots.filter(
        (nombrePoligono) => !optionalNombresPoligonos.includes(nombrePoligono),
      ); // Mantener los activos ocultos
      setHiddenLots([...currentHidden, ...optionalNombresPoligonos]);
    } else {
      // Mostrar todos los lotes opcionales
      setHiddenLots(
        hiddenLots.filter(
          (nombrePoligono) =>
            !optionalNombresPoligonos.includes(nombrePoligono),
        ),
      );
    }
  };

  // Función para filtrar lotes basado en el término de búsqueda
  const filterLotsBySearch = (lots: LotPolygon[]) => {
    return lots.filter(
      (lot) =>
        lot.nombre?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lot.nombrePoligono?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  // Modificar la función para que filtre los lotes
  const matchesSearch = (lot: LotPolygon) => {
    if (!searchTerm) return true;
    const searchTermLower = searchTerm.toLowerCase();

    // Normalizar la búsqueda para "polígono" o "poligono"
    const normalizedSearch = searchTermLower
      .replace('polígono', 'poligono')
      .replace('poligon', 'poligono');

    // Normalizar los nombres del lote
    const normalizedNombre = lot.nombre
      ?.toLowerCase()
      .replace('polígono', 'poligono')
      .replace('poligon', 'poligono');
    const normalizedPoligono = lot.nombrePoligono
      ?.toLowerCase()
      .replace('polígono', 'poligono')
      .replace('poligon', 'poligono');

    return (
      normalizedNombre?.includes(normalizedSearch) ||
      normalizedPoligono?.includes(normalizedSearch)
    );
  };

  return (
    <Box
      zIndex={10}
      sx={{
        height: {
          xs: 'calc(100vh - 266px)',
          md: 'calc(100vh - 308px)',
        },
        width: {
          xs: '100%',
          md: '308px',
        },
      }}
      position="relative"
    >
      <HiddenInput
        accept=".kml,.kmz"
        id="contained-button-file"
        multiple
        ref={hiddenFileInput}
        type="file"
        onChange={uploadKML}
      />
      <Collapse
        in={showTutorial || (lots.length > 0 && hasAssignedLots.length === 0)}
      >
        <Box
          m="16px 12px 0px 16px"
          display={{
            xs: 'none',
            md: 'block',
          }}
        >
          <img
            src={TutorialGif}
            alt="tutorial-lotes-draw"
            style={{
              width: '100%',
            }}
            loading="lazy"
          />
          <P3 color={colors.tertiaryBase} style={{ marginBottom: '16px' }}>
            Dibujá un polígono en el mapa y asignalo a un lote activo.
          </P3>
          {lots.length > 0 && hasAssignedLots.length !== 0 && (
            <Button
              text="OK, entendido"
              variant="outlined"
              preset="normal"
              size="small"
              onClick={() => setShowTutorial(false)}
            />
          )}
        </Box>
      </Collapse>

      <Collapse
        in={!showTutorial || (lots.length > 0 && hasAssignedLots.length === 0)}
      >
        {lots.length > 0 && hasAssignedLots.length !== 0 && (
          <Box
            display={{
              xs: 'none',
              md: 'block',
            }}
          >
            <P3
              color={colors.tertiaryBase}
              style={{ margin: '16px 12px 0px 16px' }}
            >
              Delimitá en el mapa
              <MapDraw
                style={{
                  verticalAlign: 'middle',
                  margin: '0 5px 2px 5px',
                }}
              />
              o importá el polígono de cada lote de tu campo
            </P3>
            <Box m="9px 12px 0 16px" width="calc(100% - 12px - 16px)">
              <Box
                display="flex"
                justifyContent="flex-end"
                gap="3px"
                onClick={() => setShowTutorial(true)}
                sx={{ cursor: 'pointer' }}
              >
                <Caps
                  color={colors.primaryBase}
                  style={{ textDecoration: 'underline' }}
                >
                  MÁS INFO
                </Caps>
                <InfoOutlinedIcon
                  sx={{
                    color: colors.primaryBase,
                    width: 16,
                    height: 16,
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          maxHeight={{
            xs:
              hasAssignedLots.length === 0
                ? 'calc(100vh - 400px)'
                : 'calc(100vh - 395px)',
            md:
              hasAssignedLots.length === 0
                ? 'calc(100vh - 700px)'
                : 'calc(100vh - 395px)',
          }}
          minHeight={{
            xs:
              hasAssignedLots.length === 0
                ? lots.length > 0
                  ? 'calc(100vh - 500px)'
                  : 'calc(100vh - 395px)'
                : 'calc(100vh - 395px)',
            md:
              hasAssignedLots.length === 0
                ? lots.length > 0
                  ? 'calc(100vh - 500px)'
                  : 'calc(100vh - 295px)'
                : 'calc(100vh - 395px)',
          }}
          mt="16px"
          sx={{ overflowY: 'auto' }}
          className="item-lotes"
        >
          <LotesDropdown
            open={open}
            handleClosePopover={handleClosePopover}
            anchorEl={anchorEl}
            handleSelectItem={handleSelectItem}
            perimeters={perimeters}
            lots={lots}
            selectedLot={selectedIdLot}
          />
          {/* Agregar el campo de búsqueda */}
          <Box mx="16px" mb="8px">
            <TextField
              fullWidth
              size="small"
              placeholder="Buscar polígonos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: colors.tertiaryMedium }} />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: colors.white,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.tertiaryLow,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.tertiaryMedium,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.primaryBase,
                  },
                },
              }}
            />
          </Box>

          <Box
            display="flex"
            width="calc(100% - 32px)"
            margin="0px 16px 8px 16px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" gap="8px" alignItems="center">
              <H6 color={colors.tertiaryMedium}>Lotes activos</H6>
              <CustomTooltip
                title="Con cultivos planificados para la campaña actual"
                arrowLocation="top"
              >
                <InfoOutlinedIcon
                  sx={{
                    color: colors.tertiaryMedium,
                    width: 16,
                    height: 16,
                    cursor: 'pointer',
                  }}
                />
              </CustomTooltip>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <P4 sx={{ fontSize: '12px' }} color={colors.tertiaryMedium}>
                Ocultar lotes
              </P4>
              <InputCheckbox
                checked={hasPlanificado.every((lot) =>
                  hiddenLots.includes(String(lot.nombrePoligono)),
                )}
                indeterminate={
                  hasPlanificado.some((lot) =>
                    hiddenLots.includes(String(lot.nombrePoligono)),
                  ) &&
                  !hasPlanificado.every((lot) =>
                    hiddenLots.includes(String(lot.nombrePoligono)),
                  )
                }
                onChange={handleHideAllActiveLots}
                name="hideActiveLots"
                error={false}
                disabled={false}
                sx={{
                  '&.MuiCheckbox-indeterminate ': {
                    color: colors.complementaryGreen,
                    borderRadius: '2px',
                  },
                }}
              />
            </Box>
          </Box>

          {hasPlanificado.length > 0 ? (
            <Box mx="16px" mb="8px" width="calc(100% - 32px)">
              {hasPlanificado.filter(matchesSearch).map((lot) => {
                return (
                  <Box
                    key={lot.id}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    my="8px"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <P4 color={colors.tertiaryMedium}>{lot.nombre}</P4>
                      <ListHoverItemLot
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                          setSelectedIdLot(lot.id);
                          handleOpenPopover(event);
                        }}
                        sx={{ padding: '0px', maxWidth: '230px' }}
                      >
                        {lot.assigned ? (
                          <P3
                            color={
                              lot.assigned
                                ? colors.tertiaryLow
                                : colors.tertiaryBase
                            }
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {lot.nombrePoligono}
                            <ArrowDropDownIcon fontSize="small" />
                          </P3>
                        ) : (
                          <ListHoverItemLot sx={{ padding: '0px' }}>
                            <P3
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              Seleccionar polígono
                            </P3>
                            <ArrowDropDownIcon fontSize="small" />
                          </ListHoverItemLot>
                        )}
                      </ListHoverItemLot>
                    </Box>
                    {lot.assigned ? (
                      <Box display="flex" alignItems="center" gap={1}>
                        <ListItemButton
                          dense
                          disableRipple
                          sx={{
                            padding: '0px',
                            alignSelf: 'flex-end',
                            justifyContent: 'flex-end',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          onClick={() => {
                            setZoomPolygon(lot.poligono);
                            setGpsIcon(lot.id);
                          }}
                        >
                          <Box
                            width="28px"
                            height="28px"
                            borderRadius="8px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            border={`1px solid ${lot.color}`}
                            bgcolor={`${lot.color}`}
                          >
                            <CustomTooltip
                              title="Ubicar en mapa"
                              arrowLocation="top"
                            >
                              {gpsIcon === lot.id ? (
                                <GpsFixedIcon
                                  style={{
                                    fontSize: '20px',
                                    color: colors.white,
                                  }}
                                />
                              ) : (
                                <GpsNotFixedIcon
                                  style={{
                                    fontSize: '20px',
                                    color: colors.white,
                                  }}
                                />
                              )}
                            </CustomTooltip>
                          </Box>
                        </ListItemButton>
                        <ListItemButton
                          dense
                          disableRipple
                          sx={{
                            padding: '0px',
                            minWidth: '28px',
                            height: '28px',
                            alignSelf: 'flex-end',
                            justifyContent: 'center',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          onClick={() =>
                            toggleLotVisibility(String(lot.nombrePoligono))
                          }
                        >
                          <CustomTooltip
                            title={
                              hiddenLots.includes(String(lot.nombrePoligono))
                                ? 'Mostrar lote'
                                : 'Ocultar lote'
                            }
                            arrowLocation="top"
                          >
                            <Box
                              width="28px"
                              height="28px"
                              borderRadius="8px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {hiddenLots.includes(
                                String(lot.nombrePoligono),
                              ) ? (
                                <VisibilityOffIcon
                                  style={{
                                    fontSize: '20px',
                                    color: colors.tertiaryMedium,
                                  }}
                                />
                              ) : (
                                <VisibilityIcon
                                  style={{
                                    fontSize: '20px',
                                    color: colors.tertiaryMedium,
                                  }}
                                />
                              )}
                            </Box>
                          </CustomTooltip>
                        </ListItemButton>
                      </Box>
                    ) : (
                      <Box
                        width="28px"
                        height="28px"
                        borderRadius="8px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border={`1px solid ${colors.tertiaryMedium}`}
                        bgcolor="transparent"
                      >
                        <WarningAmberIcon
                          sx={{
                            fontSize: '16px',
                            color: colors.tertiaryMedium,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          ) : (
            <P4
              color={colors.tertiaryMedium}
              style={{ margin: '2px 16px 16px' }}
            >
              No encontramos lotes activos en tu sistema de gestión
            </P4>
          )}

          <Box>
            {hasNotPlanificados.length !== 0 && (
              <>
                <Divider
                  sx={{
                    width: 'calc(100% - 32px)',
                    margin: '0 16px',
                  }}
                />
                <Box
                  display="flex"
                  margin="16px 16px 8px 16px"
                  justifyContent="space-between"
                  alignItems="center"
                  width="calc(100% - 32px)"
                >
                  <Box display="flex" gap="8px" alignItems="center">
                    <H6 color={colors.tertiaryMedium}>Lotes opcionales</H6>
                    <CustomTooltip
                      title="No aptos para asegurar por no tener cultivos planificados"
                      arrowLocation="top"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: colors.tertiaryMedium,
                          width: 16,
                          height: 16,
                          cursor: 'pointer',
                        }}
                      />
                    </CustomTooltip>
                  </Box>

                  <Box display="flex" alignItems="center" gap={1}>
                    <P4 sx={{ fontSize: '12px' }} color={colors.tertiaryMedium}>
                      Ocultar lotes
                    </P4>
                    <InputCheckbox
                      checked={hasNotPlanificados.every((lot) =>
                        hiddenLots.includes(String(lot.nombrePoligono)),
                      )}
                      indeterminate={
                        hasNotPlanificados.some((lot) =>
                          hiddenLots.includes(String(lot.nombrePoligono)),
                        ) &&
                        !hasNotPlanificados.every((lot) =>
                          hiddenLots.includes(String(lot.nombrePoligono)),
                        )
                      }
                      onChange={handleHideAllOptionalLots}
                      name="hideOptionalLots"
                      error={false}
                      disabled={false}
                      defaultChecked={false}
                      sx={{
                        '&.MuiCheckbox-indeterminate': {
                          color: colors.complementaryGreen,
                          borderRadius: '2px',
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  onClick={() => setHideOptionalLots(!hideOptionalLots)}
                  sx={{ cursor: 'pointer' }}
                >
                  <P4
                    color={colors.tertiaryLow}
                    style={{
                      margin: '0px 16px 8px 16px',
                      textDecoration: 'underline',
                      textUnderlineOffset: '2px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {hideOptionalLots ? 'Ocultar' : 'Mostrar'}&nbsp;
                    {lots.filter((lot) => !lot.isPlanificado).length} lotes
                  </P4>
                </Box>
              </>
            )}

            <Box mx="16px" mb="8px" height="100%" width="calc(100% - 32px)">
              <Collapse in={hideOptionalLots}>
                {lots
                  .filter((lot) => !lot.isPlanificado)
                  .filter(matchesSearch)
                  .map((lot) => {
                    return (
                      <Box
                        key={`${lot.id}${lot.nombre}`}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        my="8px"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <P4 color={colors.tertiaryMedium}>{lot.nombre}</P4>
                          <ListHoverItemLot
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>,
                            ) => {
                              setSelectedIdLot(lot.id);
                              handleOpenPopover(event);
                            }}
                            sx={{ padding: '0px', maxWidth: '230px' }}
                          >
                            {lot.assigned ? (
                              <P3
                                color={
                                  lot.assigned
                                    ? colors.tertiaryLow
                                    : colors.tertiaryBase
                                }
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {lot.load ? lot.name : lot.nombrePoligono}
                                <ArrowDropDownIcon fontSize="small" />
                              </P3>
                            ) : (
                              <ListHoverItemLot sx={{ padding: '0px' }}>
                                <P3
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  Seleccionar polígono
                                </P3>
                                <ArrowDropDownIcon fontSize="small" />
                              </ListHoverItemLot>
                            )}
                          </ListHoverItemLot>
                        </Box>
                        {lot.assigned ? (
                          <Box display="flex" alignItems="center" gap={1}>
                            <ListItemButton
                              dense
                              disableRipple
                              sx={{
                                padding: '0px',
                                alignSelf: 'flex-end',
                                justifyContent: 'flex-end',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              onClick={() => {
                                setZoomPolygon(lot.poligono);
                                setGpsIcon(lot.id);
                              }}
                            >
                              <Box
                                width="28px"
                                height="28px"
                                borderRadius="8px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border={`1px solid ${lot.color}`}
                                bgcolor={`${lot.color}`}
                              >
                                <CustomTooltip
                                  title="Ubicar en mapa"
                                  arrowLocation="top"
                                >
                                  {gpsIcon === lot.id ? (
                                    <GpsFixedIcon
                                      style={{
                                        fontSize: '20px',
                                        color: colors.white,
                                      }}
                                    />
                                  ) : (
                                    <GpsNotFixedIcon
                                      style={{
                                        fontSize: '20px',
                                        color: colors.white,
                                      }}
                                    />
                                  )}
                                </CustomTooltip>
                              </Box>
                            </ListItemButton>
                            <ListItemButton
                              dense
                              disableRipple
                              sx={{
                                padding: '0px',
                                minWidth: '28px',
                                height: '28px',
                                alignSelf: 'flex-end',
                                justifyContent: 'center',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              onClick={() =>
                                toggleLotVisibility(String(lot.nombrePoligono))
                              }
                            >
                              <CustomTooltip
                                title={
                                  hiddenLots.includes(
                                    String(lot.nombrePoligono),
                                  )
                                    ? 'Mostrar lote'
                                    : 'Ocultar lote'
                                }
                                arrowLocation="top"
                              >
                                <Box>
                                  {hiddenLots.includes(
                                    String(lot.nombrePoligono),
                                  ) ? (
                                    <VisibilityOffIcon
                                      style={{
                                        fontSize: '20px',
                                        color: colors.tertiaryMedium,
                                      }}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      style={{
                                        fontSize: '20px',
                                        color: colors.tertiaryMedium,
                                      }}
                                    />
                                  )}
                                </Box>
                              </CustomTooltip>
                            </ListItemButton>
                          </Box>
                        ) : (
                          <Box
                            width="28px"
                            height="28px"
                            borderRadius="8px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            border={`1px solid ${colors.tertiaryMedium}`}
                            bgcolor="transparent"
                          >
                            <WarningAmberIcon
                              sx={{
                                fontSize: '16px',
                                color: colors.tertiaryMedium,
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Collapse>
            </Box>
          </Box>

          <Box>
            {selectedHasPolygon.length > 0 && (
              <>
                <Divider
                  sx={{
                    width: 'calc(100% - 32px)',
                    margin: '0 16px',
                  }}
                />
                <Box
                  display="flex"
                  margin="16px 16px 8px 16px"
                  justifyContent="space-between"
                  alignItems="center"
                  width="calc(100% - 32px)"
                >
                  <Box display="flex" gap="8px" alignItems="center">
                    <H6 color={colors.tertiaryMedium}>Lotes sin asignar</H6>
                    <CustomTooltip
                      title="Polígonos dibujados que aún no han sido asignados a ningún lote"
                      arrowLocation="top"
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: colors.tertiaryMedium,
                          width: 16,
                          height: 16,
                          cursor: 'pointer',
                        }}
                      />
                    </CustomTooltip>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <P4 sx={{ fontSize: '12px' }} color={colors.tertiaryMedium}>
                      Ocultar polígonos
                    </P4>
                    <InputCheckbox
                      checked={selectedHasPolygon.every((polygon) =>
                        hiddenLots.includes(String(polygon.nombrePoligono)),
                      )}
                      indeterminate={
                        selectedHasPolygon.some((polygon) =>
                          hiddenLots.includes(String(polygon.nombrePoligono)),
                        ) &&
                        !selectedHasPolygon.every((polygon) =>
                          hiddenLots.includes(String(polygon.nombrePoligono)),
                        )
                      }
                      onChange={(event) => {
                        const unassignedNombrepoligonos = selectedHasPolygon
                          .map((polygon) => polygon.nombrePoligono)
                          .filter(
                            (nombre): nombre is string => nombre !== undefined,
                          );

                        if (event.target.checked) {
                          const currentHidden = hiddenLots.filter(
                            (nombrePoligono) =>
                              !unassignedNombrepoligonos.includes(
                                nombrePoligono,
                              ),
                          );
                          setHiddenLots([
                            ...currentHidden,
                            ...unassignedNombrepoligonos,
                          ]);
                        } else {
                          setHiddenLots(
                            hiddenLots.filter(
                              (nombrePoligono) =>
                                !unassignedNombrepoligonos.includes(
                                  nombrePoligono,
                                ),
                            ),
                          );
                        }
                      }}
                      name="hideUnassignedLots"
                      error={false}
                      disabled={false}
                    />
                  </Box>
                </Box>

                <Box mx="16px" mb="8px" height="100%" width="calc(100% - 32px)">
                  {selectedHasPolygon.map((perimeter) => (
                    <Box
                      key={perimeter.nombrePoligono}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      my="8px"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <P3 color={colors.tertiaryLow}>
                          {perimeter.nombrePoligono}
                        </P3>
                      </Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <ListItemButton
                          dense
                          disableRipple
                          sx={{
                            padding: '0px',
                            alignSelf: 'flex-end',
                            justifyContent: 'flex-end',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          onClick={() => {
                            // @ts-ignore
                            setZoomPolygon(perimeter.polygon);
                          }}
                        >
                          <Box
                            width="28px"
                            height="28px"
                            borderRadius="8px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            border={`1px solid ${colors.tertiaryMedium}`}
                            bgcolor={colors.tertiaryMedium}
                          >
                            <CustomTooltip
                              title="Ubicar en mapa"
                              arrowLocation="top"
                            >
                              <GpsNotFixedIcon
                                style={{
                                  fontSize: '20px',
                                  color: colors.white,
                                }}
                              />
                            </CustomTooltip>
                          </Box>
                        </ListItemButton>
                        <ListItemButton
                          dense
                          disableRipple
                          sx={{
                            padding: '0px',
                            minWidth: '28px',
                            height: '28px',
                            alignSelf: 'flex-end',
                            justifyContent: 'center',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          onClick={() =>
                            toggleLotVisibility(
                              String(perimeter.nombrePoligono),
                            )
                          }
                        >
                          <CustomTooltip
                            title={
                              hiddenLots.includes(
                                String(perimeter.nombrePoligono),
                              )
                                ? 'Mostrar polígono'
                                : 'Ocultar polígono'
                            }
                            arrowLocation="top"
                          >
                            <Box>
                              {hiddenLots.includes(
                                String(perimeter.nombrePoligono),
                              ) ? (
                                <VisibilityOffIcon
                                  style={{
                                    fontSize: '20px',
                                    color: colors.tertiaryMedium,
                                  }}
                                />
                              ) : (
                                <VisibilityIcon
                                  style={{
                                    fontSize: '20px',
                                    color: colors.tertiaryMedium,
                                  }}
                                />
                              )}
                            </Box>
                          </CustomTooltip>
                        </ListItemButton>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignSelf="center"
          alignItems="center"
          marginY="16px"
          padding="0 16px"
          gap="10px"
        >
          <Button
            variant="outlined"
            preset="normal"
            size="small"
            fullWidth
            loading={loadingKML}
            onClick={() => {
              if (hiddenFileInput?.current && !loadingKML) {
                hiddenFileInput.current.click();
              }
            }}
            text="Importar polígonos"
          />
          <Button
            variant="contained"
            preset="normal"
            size="small"
            fullWidth
            onClick={handleOnSubmit}
            text="Guardar cambios"
            endIcon={<ChevronRight />}
            loading={isLoading}
            disabled={isLoading || isNewLotsAssigned() || lots.length === 0}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default observer(LotesSelection);
