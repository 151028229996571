import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Paper } from '@mui/material';
import type {
  Field,
  PartialGPStylesMap,
  GridPatternStyles,
} from 'models/fields';
import EstablecimientoDetailDrawer from 'components/common/drawer/EstablecimientoDetail';
import Map from 'components/common/map';
import { concatFieldsArr } from 'utils/mapUtils';
import { tabModes } from 'utils/common';
import { useStores } from 'store/root-store/root-store-context';
import type { ScreenField } from 'store/fields-store/fields-store';
import GridField from './gridField';
import GridHeader from './gridHeader';

type Props = {
  searchedField?: Field[];
  grows?: { id: number; nombre: string }[];
  gridStyle: GridPatternStyles;
  withCheckbox?: boolean;
  withWeather?: boolean;
  hasErrors?: boolean;
  setUpdatedField?: (updated: boolean) => void;
  screen?: ScreenField;
  setFieldsIds?: (fieldsIds: number[]) => void;
  setHasErrors?: (hasErrors: boolean) => void;
  getFields?: (onEdit: boolean) => void;
};

type FieldStore = Omit<Field, 'tsPoligono' | 'tsHectareas'>;

const gridPatternStyles: PartialGPStylesMap = {
  puma: {
    xs: {
      checkbox: 1.5,
      name: 5,
      grows: 3,
      actions: 'auto',
    },
    tablet: {
      checkbox: 'auto',
      icon: 0.8,
      name: 4.5,
      grows: 2.5,
      actions: 'auto',
    },
  },
  misCampos: {
    xs: {
      name: 6,
      icon: 0.8,
      actions: 'auto',
    },
    tablet: {
      name: 4.5,
      icon: 0.8,
      actions: 'auto',
    },
  },
  seguros: {
    xs: {
      checkbox: 'auto',
      icon: 0.8,
      name: 7,
      actions: 'auto',
    },
    tablet: {},
  },
};

const FieldsTable = ({
  searchedField,
  grows = [],
  gridStyle,
  withCheckbox = false,
  withWeather = false,
  hasErrors,
  screen = 'MIS_CAMPOS',
  setFieldsIds,
  setHasErrors,
  setUpdatedField,
  getFields,
}: Props) => {
  const {
    fieldsStore,
    fieldsStore: { fields },
    sessionStore,
  } = useStores();
  const { alias } = useParams();
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMapDrawer, setOpenMapDrawer] = useState(false);
  const [fieldIdSelected, setFieldIdSelected] = useState<number>(0);

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = () => {
    if (selected.length !== fields.length) {
      const newSelected = fields.map((field) => field.id);
      setSelected(newSelected);
      setFieldsIds?.(newSelected);
      return;
    }
    setSelected([]);
    setFieldsIds?.([]);
  };

  const handleSelectField = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setFieldsIds?.(newSelected);
    setSelected(newSelected);
  };

  const getLotes = async (id: number) => {
    if (alias && screen === 'HC_PUMA') {
      const especiesIds = grows.map((g) => g.id).join(',');
      await fieldsStore.getMyLotsByAlias(alias, id, especiesIds);
    } else {
      await fieldsStore.getMyLots(id);
    }
  };

  const openMapDrawerInTab = async (tab: 'Campo' | 'Lotes') => {
    if (fieldIdSelected) {
      const fieldFinded = fields.find(
        (f: FieldStore) => f.id === fieldIdSelected,
      );
      if (fieldFinded) {
        await getLotes(fieldFinded.id);
        setFieldIdSelected(fieldFinded.id);
      }
    }
    fieldsStore.setTabMode(tab);
    setOpenMapDrawer(true);
  };

  const updateField = async () => {
    if (setUpdatedField) {
      setUpdatedField(true);
    }
    if (alias) {
      if (screen === 'HC_PUMA') {
        const especiesIds = grows.map((g) => g.id).join(',');
        await fieldsStore.getFieldHC(alias, fieldIdSelected, especiesIds);
      }
    }
  };

  /**
   * @description Al abrir el Map, se actualiza el campo seleccionado, se setea el tab
   * y se obtienen los lotes
   */
  const handleOpenMap = async (id: number) => {
    await getLotes(id);
    setFieldIdSelected(id);
    fieldsStore.setTabMode(tabModes.CAMPO as 'Campo' | 'Lotes');
    setOpenMapDrawer(true);
  };

  /**
   * @description Al abrir el drawer EstablecimientoDetailDrawer, se actualiza el campo seleccionado
   * y se obtienen los lotes
   */
  const handleOpenEdit = async (id: number) => {
    await getLotes(id);
    setFieldIdSelected(id);
    setOpenDrawer(true);
  };

  const fieldSelected = fields
    .filter((f: FieldStore) => f.id === fieldIdSelected)
    .pop();

  // Cuando se cambia de tab (Lotes -> Campo),
  // se obtienen los lotes del campo seleccionado
  useEffect(() => {
    if (fieldIdSelected && fieldsStore.tabMode === tabModes.CAMPO) {
      getLotes(fieldIdSelected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsStore.tabMode]);

  return (
    <Box sx={{ width: '100%' }}>
      <EstablecimientoDetailDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          updateField();
        }}
        openMapCampo={() => openMapDrawerInTab('Campo')}
        openMapLotes={() => openMapDrawerInTab('Lotes')}
        field={fieldSelected ?? null}
      />
      <Map
        openDrawer={openMapDrawer}
        closeDrawer={() => {
          setOpenMapDrawer(false);
          updateField();
        }}
        field={fieldSelected}
      />
      {gridStyle !== 'misCampos' && (
        <GridHeader
          selectedLength={selected.length}
          fieldsLength={searchedField?.length ?? 0}
          gridPattern={gridPatternStyles[gridStyle]}
          onSelectAllClick={handleSelectAllClick}
        />
      )}
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
          mt: '12px',
          mb: 2,
          bgcolor: 'transparent',
        }}
        elevation={0}
      >
        {searchedField &&
          concatFieldsArr(searchedField).map((field) => {
            const checked = isSelected(field.id);
            const growsAvailables: { [key: string]: boolean } = {};
            grows.forEach((grow) => {
              growsAvailables[grow.nombre] = Boolean(
                field.especies?.includes(grow.nombre),
              );
            });

            const fieldValidation = fieldsStore.isFieldValid(field.id, screen);
            if (fieldValidation.error) {
              setHasErrors?.(Boolean(hasErrors || checked));
            }

            return (
              <GridField
                key={field.id}
                screen={screen}
                field={field}
                fieldValidation={fieldValidation}
                checked={checked}
                gridPattern={gridPatternStyles[gridStyle]}
                grows={growsAvailables}
                withCheckbox={withCheckbox}
                withWeather={withWeather}
                splitioClima={sessionStore.splitioClima}
                setChecked={() => handleSelectField(field.id)}
                openEdit={() => handleOpenEdit(field.id)}
                openMap={() => handleOpenMap(field.id)}
              />
            );
          })}
      </Paper>
    </Box>
  );
};

export default observer(FieldsTable);
