import { lazy } from 'react';

export const LazyComunityScreen = lazy(() => import('./comunidad'));
export const LazyMyCertificationsScreen = lazy(
  () => import('./sustentabilidad/index'),
);
export const LazyMyFieldsScreen = lazy(
  () => import('./mis-datos/mis-campos/index'),
);
export const LazyMyAccountScreen = lazy(() => import('./mis-empresas/index'));
export const LazyAuditoriaDashboardScreen = lazy(
  () => import('./auditoria-digital/index'),
);
export const LazyUsersScreen = lazy(() => import('./usuarios-invitados/index'));
export const LazyCultivosScreen = lazy(
  () => import('./mis-datos/cultivos/index'),
);
export const LazyOrdenesTrabajoScreen = lazy(
  () => import('./mis-datos/ordenes-trabajo/index'),
);

export const LazyIntegratedApp = lazy(() => import('./insumos/index'));
