import React from 'react';
import styled from 'styled-components';

const AgrologyIconBase = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="35"
      viewBox="0 0 1680 363"
      fill="none"
    >
      <path
        d="M107.3 164.2H65.6C60.8 164.2 56.9 168.1 56.9 172.9V194.2C56.7 199.7 54.5 220.1 29.9 219.9H8.99998C4.19998 219.9 0.299988 223.8 0.299988 228.6V270.3C0.299988 275.1 4.19998 279 8.99998 279H50.7C55.5 279 59.4 275.1 59.4 270.3V249.3C59.4 247.3 60.1 223.3 87.4 223.3H107.2C112 223.3 115.9 219.4 115.9 214.6V172.9C115.9 168.1 112 164.2 107.2 164.2H107.3Z"
        fill="white"
      />
      <path
        d="M248.5 268.9L144.1 24.4C143.4 22.1 142.5 20 141.3 17.9C136.8 9.79999 128.9 3.80001 119.5 1.70001C117.2 1.20001 114.9 0.899994 112.4 0.899994C109.9 0.899994 107.6 1.20001 105.3 1.70001C100 2.90001 91.8 6.50001 85.9 14.2L77.6 32.4L43.3 112.4C40.8 118.1 45 124.5 51.3 124.5H67.1C71.8 124.5 76.1 121.7 78 117.3L100.5 64.7L111.3 39.4C111.5 38.9 112 38.6 112.5 38.6C113 38.6 113.5 38.9 113.7 39.4L124.2 64.2L162 154.9L212.7 276.5C214.1 278.2 216.2 279.3 218.6 279.3H241.5C247 279.3 250.6 273.7 248.5 268.7V268.9Z"
        fill="white"
      />
      <path
        d="M478.7 80.5H451.1C447.7 80.5 445 83.2 445 86.6V104.9C445 108.3 442.3 111 438.9 111H434.4C432.3 111 430.4 109.9 429.3 108.1C422.9 98.2 414.4 90.6 403.9 85.3C392.4 79.4 378.7 76.5 362.8 76.5C333.6 76.5 310.6 85.6 293.8 103.8C277 122 268.6 146.7 268.6 177.9C268.6 209.1 272.4 216.6 279.9 231.8C287.4 246.9 298.1 258.7 312.1 266.9C326 275.2 342.4 279.3 361.2 279.3C380 279.3 390.5 276.2 402 269.8C413.5 263.5 421.9 254.5 427.2 242.8H428.9V275C428.9 293.1 423.4 307.1 412.3 316.9C401.2 326.7 385.3 331.7 364.6 331.7C333.8 331.7 314.9 321.7 307.8 301.7C307 299.3 304.6 297.8 302.1 297.8H274.9C270.9 297.8 268 301.6 269 305.4C273.3 322.1 282.9 335.5 298 345.5C315.2 357 337.7 362.7 365.3 362.7C392.9 362.7 421.8 355.1 439.4 340C457 324.9 465.8 303.7 465.8 276.5V124.8C465.8 121.4 468.5 118.7 471.9 118.7H478.9C482.3 118.7 485 116 485 112.6V86.3C485 82.9 482.3 80.2 478.9 80.2L478.7 80.5ZM428.4 191.3C428.4 208.1 423 221.6 412.3 231.7C401.6 241.9 387.5 247 370 247C352.5 247 334.1 241 322.8 228.9C311.4 216.9 305.7 199.9 305.7 178C305.7 156.1 311.4 139.2 322.8 127.1C334.2 115.1 349.9 109 370 109C390.1 109 401.5 114.1 412.3 124.3C423 134.5 428.4 148 428.4 164.7V191.2V191.3Z"
        fill="white"
      />
      <path
        d="M652.1 78.9H641.9C626.2 78.9 612.6 82.3 601 89.2C590.5 95.4 582.5 103.8 577 114.3C575.9 116.3 573.9 117.7 571.6 117.7H567.5C564.1 117.7 561.4 115 561.4 111.6V86.7C561.4 83.3 558.7 80.6 555.3 80.6H527.7C524.3 80.6 521.6 83.3 521.6 86.7V113C521.6 116.4 524.3 119.1 527.7 119.1H534.7C538.1 119.1 540.8 121.8 540.8 125.2V273.5C540.8 276.9 543.5 279.6 546.9 279.6H571.5C574.9 279.6 577.6 276.9 577.6 273.5V175.8C577.6 155.9 583 140.1 593.8 128.4C604.6 116.7 620 110.8 639.9 110.8H652C655.4 110.8 658.1 108.1 658.1 104.7V85.1C658.1 81.7 655.4 79 652 79L652.1 78.9Z"
        fill="white"
      />
      <path
        d="M828.4 89.2C813.3 80.8 795.1 76.6 773.9 76.6C752.7 76.6 735.4 80.9 720 89.4C704.6 97.9 692.8 109.9 684.4 125.4C676 140.9 671.8 159.1 671.8 180.1C671.8 201.1 676 219.3 684.4 234.8C692.8 250.3 704.7 262.3 720 270.8C735.4 279.3 753.3 283.6 773.9 283.6C794.5 283.6 813 279.4 828.3 271C843.6 262.6 855.3 250.6 863.6 235C871.9 219.4 876 201.1 876 180.1C876 159.1 871.9 140.5 863.6 125.1C855.3 109.6 843.6 97.7 828.5 89.3L828.4 89.2ZM821.8 232.2C810.4 244.7 794.4 250.9 773.9 250.9C753.4 250.9 737.6 244.7 726.2 232.2C714.7 219.7 709 202.3 709 180C709 157.7 714.7 140.3 726.2 127.8C737.7 115.3 753.6 109.1 773.9 109.1C794.2 109.1 810.4 115.3 821.8 127.7C833.2 140.1 838.9 157.5 838.9 180.1C838.9 202.7 833.2 219.8 821.8 232.3V232.2Z"
        fill="white"
      />
      <path
        d="M926.5 1H951.4C954.8 1 957.5 3.70001 957.5 7.10001V273.4C957.5 276.8 954.8 279.5 951.4 279.5H926.5C923.1 279.5 920.4 276.8 920.4 273.4V7.10001C920.4 3.70001 923.1 1 926.5 1Z"
        fill="white"
      />
      <path
        d="M1158.6 89.2C1143.5 80.8 1125.3 76.6 1104.1 76.6C1082.9 76.6 1065.6 80.9 1050.2 89.4C1034.8 97.9 1023 109.9 1014.6 125.4C1006.2 140.9 1002 159.1 1002 180.1C1002 201.1 1006.2 219.3 1014.6 234.8C1023 250.3 1034.9 262.3 1050.2 270.8C1065.6 279.3 1083.5 283.6 1104.1 283.6C1124.7 283.6 1143.2 279.4 1158.5 271C1173.8 262.6 1185.5 250.6 1193.8 235C1202.1 219.4 1206.2 201.1 1206.2 180.1C1206.2 159.1 1202.1 140.5 1193.8 125.1C1185.5 109.6 1173.8 97.7 1158.7 89.3L1158.6 89.2ZM1152 232.2C1140.6 244.7 1124.6 250.9 1104.1 250.9C1083.6 250.9 1067.8 244.7 1056.4 232.2C1044.9 219.7 1039.2 202.3 1039.2 180C1039.2 157.7 1044.9 140.3 1056.4 127.8C1067.9 115.3 1083.8 109.1 1104.1 109.1C1124.4 109.1 1140.6 115.3 1152 127.7C1163.4 140.1 1169.1 157.5 1169.1 180.1C1169.1 202.7 1163.4 219.8 1152 232.3V232.2Z"
        fill="white"
      />
      <path
        d="M1448.1 80.5H1420.5C1417.1 80.5 1414.4 83.2 1414.4 86.6V104.9C1414.4 108.3 1411.7 111 1408.3 111H1403.8C1401.7 111 1399.8 109.9 1398.7 108.1C1392.3 98.2 1383.8 90.6 1373.3 85.3C1361.8 79.4 1348.1 76.5 1332.2 76.5C1303 76.5 1280 85.6 1263.2 103.8C1246.4 122 1238 146.7 1238 177.9C1238 209.1 1241.8 216.6 1249.3 231.8C1256.8 246.9 1267.5 258.7 1281.5 266.9C1295.4 275.2 1311.8 279.3 1330.6 279.3C1349.4 279.3 1359.9 276.2 1371.4 269.8C1382.9 263.5 1391.3 254.5 1396.6 242.8H1398.3V275C1398.3 293.1 1392.8 307.1 1381.7 316.9C1370.6 326.7 1354.7 331.7 1334 331.7C1303.2 331.7 1284.3 321.7 1277.2 301.7C1276.4 299.3 1274 297.8 1271.5 297.8H1244.3C1240.3 297.8 1237.4 301.6 1238.4 305.4C1242.7 322.1 1252.3 335.5 1267.4 345.5C1284.6 357 1307.1 362.7 1334.7 362.7C1362.3 362.7 1391.2 355.1 1408.8 340C1426.4 324.9 1435.2 303.7 1435.2 276.5V124.8C1435.2 121.4 1437.9 118.7 1441.3 118.7H1448.3C1451.7 118.7 1454.4 116 1454.4 112.6V86.3C1454.4 82.9 1451.7 80.2 1448.3 80.2L1448.1 80.5ZM1397.8 191.3C1397.8 208.1 1392.4 221.6 1381.7 231.7C1371 241.9 1356.9 247 1339.4 247C1321.9 247 1303.5 241 1292.2 228.9C1280.8 216.9 1275.1 199.9 1275.1 178C1275.1 156.1 1280.8 139.2 1292.2 127.1C1303.6 115.1 1319.3 109 1339.4 109C1359.5 109 1370.9 114.1 1381.7 124.3C1392.4 134.5 1397.8 148 1397.8 164.7V191.2V191.3Z"
        fill="white"
      />
      <path
        d="M1673.4 80.5H1650.2C1647.7 80.5 1645.5 82 1644.6 84.2L1581.2 236H1579.5L1512.8 84.1C1511.8 81.9 1509.6 80.5 1507.2 80.5H1482.1C1477.7 80.5 1474.7 85 1476.5 89.1L1560.8 277.7C1561.5 279.2 1561.5 281 1560.8 282.5L1542.8 325.7C1541.9 328 1539.6 329.5 1537.2 329.5H1499.3C1495.9 329.5 1493.2 332.2 1493.2 335.6V352.9C1493.2 356.3 1495.9 359 1499.3 359H1541.9C1552.7 359 1560.3 356.1 1564.6 350.2C1568.9 344.3 1573.3 336.3 1577.7 326.2L1597.6 279.5L1678.9 89.1C1680.6 85.1 1677.7 80.6 1673.3 80.6L1673.4 80.5Z"
        fill="white"
      />
    </svg>
  );
};

const AgrologyIcon = styled(AgrologyIconBase)`
  height: 90;
  width: 40;
`;

export default AgrologyIcon;
