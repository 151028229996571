/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import { controls, getPointToZoom } from 'utils/mapUtils';
import type { Perimeter } from 'utils/types';
import L, { type LatLngBoundsExpression } from 'leaflet';
import { generateRandomColor, tabModes } from 'utils/common';
import { useStores } from 'store/root-store/root-store-context';
import { observer } from 'mobx-react';
import { useMediaQuery } from '@mui/material';
import CreateLoteModal from '../modals/createLote';
import colors from 'theme/colors';
import parseHas from 'utils/parseHas';
import { Box, Divider, Collapse } from '@mui/material';

interface CustomPolygonOptions extends L.PolylineOptions {
  idOrigin?: number;
  id?: number;
  nombrePoligono: string;
}

interface MapConsumerProps {
  perimeters: Perimeter[];
  setIdPerimeter: (id: number) => void;
  setPerimeters: (perimeters: any) => void;
  loadPerimeter: 'charge' | 'load' | 'loadLotes' | 'clear' | null;
  setLoadPerimeter: (loadPerimeter: 'charge' | 'load' | null) => void;
  setSelectedPerimeter: (selectedPerimeter: Perimeter | null) => void;
  setZoom: (zoom: number) => void;
  zoomPolygon: any;
  setZoomPolygon: (a: any) => void;
  hiddenLots: string[];
  setHiddenLots: (lots: string[]) => void;
}

const MapConsumer: React.FC<MapConsumerProps> = ({
  perimeters,
  setPerimeters,
  loadPerimeter,
  setLoadPerimeter,
  setSelectedPerimeter,
  setIdPerimeter,
  setZoom,
  zoomPolygon,
  setZoomPolygon,
  hiddenLots,
  setHiddenLots,
}) => {
  const map = useMap();
  const countIdRef = useRef(0);
  const {
    mapStore,
    fieldsStore,
    sessionStore: { isExternal },
  } = useStores();
  const [isOpenLoteModal, setOpenLoteModal] = useState(false);
  const [tempDraw, setTempDraw] = useState<{
    polygon: Perimeter;
    layer: L.Layer;
  } | null>(null);
  const [hideOptionalLots, setHideOptionalLots] = useState(false);
  const [selectedHasPolygon, setSelectedHasPolygon] = useState<Perimeter[]>([]);

  const saveNewPolygon = (newPerimeter: Perimeter) => {
    const npIdx = newPerimeter.id;
    const find = !perimeters.find((perimeter) => perimeter.id === npIdx);
    if (find) {
      setPerimeters((prevState: any) => [...prevState, newPerimeter]);
    }
    countIdRef.current += 1;
  };

  const generateNewPolygonFromLayer = (layer: L.Layer) => {
    const polygonColor = generateRandomColor();
    // @ts-ignore:next-line
    const leafletId = layer._leaflet_id;

    // Establecer el estilo y el ID en las opciones de la capa
    // @ts-ignore:next-line
    layer.setStyle({
      color: polygonColor,
      id: leafletId, // Agregamos el ID a las opciones
      nombrePoligono: `Polígono ${countIdRef.current}`,
    });

    const perimeterDrawed = {
      color: polygonColor,
      name: `Polígono ${countIdRef.current}`,
      nombrePoligono: `Polígono ${countIdRef.current}`,
      // @ts-ignore:next-line
      polygon: layer._latlngs[0],
      original: false,
      drawnOrKMLPolygons: true,
      id: leafletId,
    };

    return perimeterDrawed;
  };

  const addEventsToPolygon = (layer: L.Layer, polygon: Perimeter) => {
    layer.on('click', () => {
      setSelectedPerimeter(polygon);
    });
    layer.on('mouseover', () => {
      layer.bindTooltip(polygon.name, { permanent: true }).openTooltip();
    });
    layer.on('mouseout', () => {
      layer.unbindTooltip();
    });
  };

  // width >= 768
  const minWidth = useMediaQuery('(min-width:768px)');

  // width <= 767
  const maxWidth = useMediaQuery('(max-width:767px)');

  const togglePolygonTransparencyByIdOrigin = (
    nombrePoligono: string,
    makeTransparent: boolean,
  ) => {
    map.eachLayer((layer) => {
      if (
        layer instanceof L.Polygon &&
        // @ts-ignore
        layer.options?.nombrePoligono === nombrePoligono
      ) {
        if (makeTransparent) {
          layer.setStyle({
            fillOpacity: 0,
            opacity: 0,
          });
          const element = layer.getElement();
          if (element) {
            (element as HTMLElement).style.pointerEvents = 'none';
          }
        } else {
          layer.setStyle({
            fillOpacity: 0.5,
            opacity: 1,
          });

          const element = layer.getElement();
          if (element) {
            (element as HTMLElement).style.pointerEvents = '';
          }
        }
      }
    });
  };

  useEffect(() => {
    if (loadPerimeter === 'loadLotes' || loadPerimeter === 'clear') {
      map.eachLayer((layer) => {
        if (layer instanceof L.Polygon && !layer.options.pmIgnore) {
          map.removeLayer(layer);
        }
      });
      countIdRef.current = 1;
    }

    // biome-ignore lint/complexity/noForEach: <explanation>
    perimeters.forEach((perimeter) => {
      const isHiddenNombre = hiddenLots.includes(
        String(perimeter.nombrePoligono),
      );
      togglePolygonTransparencyByIdOrigin(
        String(perimeter.nombrePoligono),
        isHiddenNombre,
      );
      // if (perimeter.idOrigin) {
      //   const isHiddenByIdOrigin = hiddenLots.includes(
      //     Number(perimeter.idOrigin),
      //   );
      //   togglePolygonTransparencyByIdOrigin(
      //     String(perimeter.nombrePoligono),
      //     isHiddenByIdOrigin,
      //   );
      // } else {
      //   const isHiddenById = hiddenLots.includes(Number(perimeter.id));
      //   togglePolygonTransparencyByIdOrigin(
      //     String(perimeter.nombrePoligono),
      //     isHiddenById,
      //   );
      // }
    });
    if (
      (perimeters &&
        perimeters.length === 1 &&
        perimeters[0].original &&
        loadPerimeter === 'charge') ||
      (perimeters && perimeters.length > 0 && loadPerimeter === 'load')
    ) {
      if (loadPerimeter === 'load') countIdRef.current = 1;
      map.eachLayer((layer) => {
        if (layer instanceof L.Polygon && !layer.options.pmIgnore) {
          map.removeLayer(layer);
        }
      });

      setZoomPolygon([]);

      const parsePerimeters: Perimeter[] = [];

      // biome-ignore lint/complexity/noForEach: <explanation>
      perimeters.forEach((perimeter) => {
        const polygon = L.polygon(perimeter.polygon, {
          nombrePoligono: `Polígono ${countIdRef.current}`,
          color: perimeter.color || colors.complementaryGreen,
          idOrigin: Number(perimeter.idOrigin),
          id: perimeter.id,
        } as CustomPolygonOptions);
        polygon.addTo(map);
        const newPolygon = {
          ...perimeter,
          name: perimeter.name ?? `Polígono ${countIdRef.current}`,
          nombrePoligono: `Polígono ${countIdRef.current}`,
          // @ts-ignore:next-line
          id: polygon._leaflet_id,
          // @ts-ignore:next-line
        };
        countIdRef.current += 1;
        parsePerimeters.push(newPolygon);
        polygon.on('click', () => {
          setSelectedPerimeter(newPolygon);
        });
        polygon.on('mouseover', () => {
          polygon
            .bindTooltip(
              `${newPolygon.name}<br/>${parseHas(
                (perimeter.hectareas ?? 0).toString(),
                true,
              )} ha.`,
              {
                permanent: true,
                className: 'leaflet-tooltip-agrology',
              },
            )
            .openTooltip();
        });
        polygon.on('mouseout', () => {
          polygon.unbindTooltip();
        });
      });

      setPerimeters(parsePerimeters);
      setLoadPerimeter(null);

      const bounds = getPointToZoom(perimeters) as LatLngBoundsExpression;
      const getZoom = map.fitBounds(bounds).getZoom();

      setZoom(getZoom);
    }
  }, [loadPerimeter, hiddenLots]);

  useEffect(() => {
    /* Controls */
    map.pm.addControls({
      ...controls,
      position: 'topleft',
      editMode: false,
      rotateMode: !!minWidth,
      removalMode: !(isExternal && fieldsStore.tabMode === tabModes.LOTES),
    });

    if (maxWidth) {
      map.zoomControl.remove();
    }

    map.pm.setLang('es');
    /* Events */
    map.on('pm:create', (e) => {
      mapStore.setDrawPolygon(false);
      const { layer } = e;
      const newPolygon = generateNewPolygonFromLayer(layer);

      if (fieldsStore.tabMode === tabModes.LOTES && isExternal) {
        setTempDraw({ polygon: newPolygon, layer });
        setOpenLoteModal(true);
      } else {
        addEventsToPolygon(layer, newPolygon);
        saveNewPolygon(newPolygon);
      }
    });

    map.on('pm:remove', (e) => {
      // @ts-ignore:next-line
      setIdPerimeter(e.layer._leaflet_id);
    });
  }, []);

  useEffect(() => {
    map.pm.addControls({
      ...controls,
      position: 'topleft',
      editMode: false,
      rotateMode: !!minWidth,
      removalMode: !(isExternal && fieldsStore.tabMode === tabModes.LOTES),
    });
    setHiddenLots([]);
  }, [fieldsStore.tabMode]);

  useEffect(() => {
    // Activate drawing mode on change react state
    if (mapStore.drawPolygon) {
      map.pm.enableDraw('Polygon');
    }
  }, [mapStore.drawPolygon]);

  useEffect(() => {
    // Activate zoom mode on change react state
    if (zoomPolygon.length > 0) {
      const zoom = map.fitBounds(zoomPolygon).getZoom();
      const center = L.latLngBounds(zoomPolygon).getCenter();
      map.setView(center, zoom);
    }
  }, [zoomPolygon]);

  useEffect(() => {
    // Remove polygon when tool is disabled
    if (mapStore.idPolygonToRemove) {
      map.eachLayer((layer) => {
        // Remove each polygon layer
        if (layer instanceof L.Polygon && !layer.options.pmIgnore) {
          // @ts-ignore:next-line
          if (layer._leaflet_id === mapStore.idPolygonToRemove) {
            map.removeLayer(layer);
          }
        }
      });
      mapStore.setIdPolygonToRemove(null);
    }
  }, [mapStore.idPolygonToRemove]);

  return (
    <CreateLoteModal
      open={isOpenLoteModal}
      polygon={tempDraw?.polygon || null}
      polygons={perimeters}
      handleClose={() => {
        if (tempDraw) {
          map.removeLayer(tempDraw.layer);
        }
        setOpenLoteModal(false);
        setTempDraw(null);
      }}
      handleCreate={(name: string) => {
        if (tempDraw) {
          tempDraw.polygon.name = name;
          tempDraw.polygon.nombrePoligono = name;
          addEventsToPolygon(tempDraw.layer, tempDraw.polygon);
          saveNewPolygon(tempDraw.polygon);
        }
        setOpenLoteModal(false);
        setTempDraw(null);
      }}
    />
  );
};

export default observer(MapConsumer);
